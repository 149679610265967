import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Pinellashero from "../components/hero/Pinellashero";
import { FaSistrix } from "react-icons/fa";

function Pinellas() {
  return (
    <Layout>
      <Pinellashero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>PINELLAS COUNTY</h3>
            <p>
              You cannot beat Pinellas county for swimming, boating and fishing,
              with 35 miles of powdery white beaches and nearly 588 miles of
              coastline. And, with an average of 361 days of sunny weather each
              year, you can enjoy the surf, sun and sand just about whenever you
              would like. Three of the top ten beaches in the nation are located
              in Pinellas County (Fort De Soto Park which has won many awards,
              Clearwater Beach and Caladesi Island State Park).
            </p>
            <p>
              St.Petersburg’s Downtown area has gone through a major revival and
              new townhomes and high rise condominiums have sprung to the demand
              of the population wanting to be part of the action. With it came a
              very art loving community and there are art galleries galore and
              some major names in the world of art call St.Pete home. The
              waterfront museums support the vibe among them the “Dali Museum”,
              And the Pier just went through a fabulous renovation and is the
              shining star on the Bay now. There is also fantastic boutique
              restaurants and shopping downtown. Then just straight ride down
              1st Ave North and you reach the Gulf of Mexico’s beaches & boating
              and island communities, there is something for everyone in the 6th
              most populous county in Florida. From Tampa Bay to the Gulf there
              is a home just waiting for you here!
            </p>
            <p>
              Pinellas County’s top key business sectors are health services,
              tourism services, manufacturing and financial services
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 974,996 (2019 census)
              </li>
              <li>
                <FaSistrix /> Founded: January 1, 1912
              </li>
              <li>
                <FaSistrix /> County Seat: Clearwater
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Pinellas County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Belleair
              </li>
              <li>
                <FaSistrix /> Clearwater
              </li>
              <li>
                <FaSistrix /> Dunedin
              </li>
              <li>
                <FaSistrix /> Gulfport
              </li>
              <li>
                <FaSistrix /> Indian Rocks Beach
              </li>
              <li>
                <FaSistrix /> Indian Shores
              </li>
              <li>
                <FaSistrix /> Kenneth City
              </li>
              <li>
                <FaSistrix /> Largo
              </li>
              <li>
                <FaSistrix /> Madeira Beach
              </li>
              <li>
                <FaSistrix /> North Redington Beach
              </li>
              <li>
                <FaSistrix /> Oldsmar
              </li>
              <li>
                <FaSistrix /> Pinellas Park
              </li>
              <li>
                <FaSistrix /> Redington Beach
              </li>
              <li>
                <FaSistrix /> Redington Shores
              </li>
              <li>
                <FaSistrix /> Safety Harbor
              </li>
              <li>
                <FaSistrix /> St. Pete Beach
              </li>
              <li>
                <FaSistrix /> St. Petersburg
              </li>
              <li>
                <FaSistrix /> Seminole
              </li>
              <li>
                <FaSistrix /> South Pasadena
              </li>
              <li>
                <FaSistrix /> Tarpon Springs
              </li>
              <li>
                <FaSistrix /> Treasure Island
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/ab64dd44a05f6740a50410fbf970d14b/8936d/pinellasmap.jpg"
              target="blank"
            >
              <StaticImage src="../images/pinellasmap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Pinellas;
